var jquery = require("/js/lib/jquery");

window.$ = window.jQuery = jquery;

import wsCall, { wsCallAsync } from './wsCall';
import appData from './appData';
import { getParameterByName } from './util';
import 'babel-polyfill';
import cookie from './cookie';

function App() {
  var self = this;
}

App.prototype.init = function() {
  var self = this;

  appData.init({
    watch: {
    },
    computed: {
    },
    methods: {
    },
    mounted: function() {
    }
  });

  setTimeout(function() {
    if (appData.vm.loginEmail) {
      $('.login-password').focus();

      document.addEventListener("foneselfFreshChatInitialized", function(event) {
        // To set unique user id in your system when it is available
        window.fcWidget.setExternalId(appData.vm.loginEmail);

        // To set user email
        window.fcWidget.user.setEmail(appData.vm.loginEmail);
      });
    }
    else {
      $('.login-email').focus();
    }
  }, 0);

  $('#loginButton').on('click', function() {
    $('#loginSubmit').click();
  });
  $('#loginForm').on('submit', async function(e) {
    e.preventDefault();

    $('#loginButton').addClass('is-loading');


    cookie.set({
      name: 'loginEmail',
      value: appData.vm.loginEmail,
      days: 100
    });

    try {
      let result = await wsCallAsync({
        url: '/account/login',
        data: {
          email: appData.vm.loginEmail,
          password: appData.vm.loginPassword
        }
      });

      appData.vm.saved.primaryPhoneVerified = result.data.data.primaryPhoneVerified;
      appData.vm.saved.primaryPhoneId = result.data.data.primaryPhoneId;
      appData.vm.saved.phoneLimit = result.data.data.phoneLimit;
      appData.vm.save();

      window.location = '/app/reminderList';
    }
    catch (result) {
      console.error('Error during login: ' + result)
    }

  });

};

var app = new App();

app.init();

export default app;

